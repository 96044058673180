@import "../../styles/variables";

.admin-tabs {
	margin-bottom: 5px;
}

.admin-style {
	display: flex;
	flex-direction: column;

	.fabric {
		font-size: 0.7em;
		color: $dagobaBlue;
	}
}

.admin-action-icon {
	font-size: 1.2em;
	cursor: pointer;
	margin-right: 5px;
}

.product-definition {
	.product-images {

		.product-img {

			img {
				width: 100%;
			}
		}
	}

	.definition-search {
		position: absolute;
		top: -10px;
		left: 40%;

		button {
			margin-left: 5px;
		}
	}

	.definition-actions {
		position: absolute;

		top: -10px;
		right: 30px;

		button {
			margin-left: 5px;
		}
	}

	.product-references {
		display: flex;
		//justify-content: space-between;
		font-size: 14px;
		padding: 0px 0px 10px;
		margin-top: -5px;

		.source {
			padding: 0px 40px 0px 0px;
			text-align: center;
			cursor: pointer;
			color: #A0A0A0;

			&.active {
				font-weight: bold;
				color: #606060;
			}
		}
	}

	.all-persos {
		padding: 10px;
		border: 1px solid #D0D0D0;
		border-radius: 5px;
	}

	.connections {
		padding: 10px;
		border: 1px solid #D0D0D0;
		border-radius: 5px;
		font-size: .9em;
		line-height: 1.3em;
	}

	.size-guide-link {
		font-size: 12px;
		color: #606060;
		margin-left: 5px;
	}

	.color-container {
		position: relative;

		.flip {
			position: absolute;
			top: -30px;
			right: 5px;
			cursor: pointer;
		}

		.add-color {
			color: $dagobaBlue;
			position: absolute;
			bottom: 1px;
			right: 4px;
			cursor: pointer;
			z-index: 1;
		}
	}

	.size-container {
		position: relative;
		min-height: 40px;
		.price {
			cursor: pointer;
			position: absolute;
			top: 0px;
			right: 0px;
			color: #000000;

			&.active {
				color: #2fad3a;
			}
		}

		.add-size {
			position: absolute;
			top: 30px;
			right: 0px;
			cursor: pointer;
			color: $dagobaBlue;
		}

		.junior-cut {
			position: absolute;
			top: 30px;
			right: 0px;
		}

		.all-sizes {
			display: flex;
			flex-wrap: wrap;
			gap: 5px;
			row-gap: 0px;
			margin-bottom: 10px;

			.size {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: #F0F0F0;
				&.warning {
					background-color: #ff9c9c;
				}
				border: 1px solid #D0D0D0;
				padding: 4px;
				border-radius: 3px;
				margin: 0;
				cursor: grab;

				.close {
					display: none;
				}
				&:hover {
					.close {
						display: flex;
						position: absolute;
						z-index: 1;
						top: -10px;
						right: -10px;
						cursor: pointer;
						color: #808080;
						svg {
							width: 15px;
							height: 15px;
						}
					}
				}


				.MuiTextField-root {
					margin-bottom: 0;
				}

				input {
					font-size: 13px;
					//width: 40px;
					text-align: center;
					background-color: white;
				}
			}
		}
	}

	.color-definition {
		display: flex;
		align-items: center;
		gap: 5px;
		border: 1px solid #D0D0D0;
		background-color: #FAFAFA;
		border-radius: 5px;
		padding: 4px 25px 0px 4px;
		margin-bottom: 3px;
		position: relative;

		.color-imgs {
			display: flex;

			.color-img-item {
				width: 35px;
				text-align: center;

				.loaded {
					color: #000000;

					&.invalid {
						color: #ff0000;
					}
				}
				img {
					width: 30px;
					height: 30px;
					//margin-right: 5px;
					cursor: pointer;

					&.delete {
						filter: brightness(70%);
					}
				}

				.img-side {
					font-size: 10px;
					cursor: pointer;

					&.low {
						color: #989898;
					}

					&.selected {
						color: white;
						background-color: black;
					}

					&.invalid {
						color: #ff0000;
						font-weight: bold;
					}
				}

				svg {
					width: 20px;
					height: 20px;
					cursor: pointer;

					color: #c9c8c8;

					&.replace {
						color: #a9092f;
					}
				}

				&.box {
					width: 20px;
					height: 15px;
					background-color: darkred;
					color: white;
					font-size: 12px;
					border-radius: 3px;
					margin-right: 2px;
					padding: 2px;
				}
			}

		}

		&.extra {
			background-color: #d7fdff;
		}

		&.missing {
			background-color: #ffd9b4;
		}

		&.disc {
			background-color: #FFDDDD;
		}

		&.remove {
			background-color: #808080;
		}

		&.add {
			background-color: #DDFFDD;
		}

		.action {
			cursor: pointer;
			position: absolute;
			right: 3px;
			top: 3px;
		}

		.add {
			color: #80DD80;
		}

		.remove {
			color: #000000;
		}


		input {
			background-color: white;
			height: 18px;
		}

	}
}

@media print {
	.admin-tabs {
		display: none !important;
	}
}


@import "variables";

.product-box {
	padding-top: 3px !important;
	padding-bottom: 7px !important;
}

.product {
	@include website-design;

	position: relative;
	height: 100%;
	max-height: 600px;
	cursor: pointer;

	&.selected {
		border: 2px solid $dagobaBlue;
		box-shadow: 0 0 4px 4px $dagobaBlue50;
	}

	.gender {
		//background-color: #505050;
		background-color: $dagobaBlue;
		color: white;
		border-radius: 7px;
		display: inline-block;
		width: 22px;
		height: 25px;
		padding: 3px;
		margin-bottom: 5px;
	}

	.brand {
		position: absolute;
		top:5px;
		left:5px;
		//background-color: #505050;
		background-color: $dagobaBlue;
		color: white;
		font-weight: 300;
		border-radius: 3px;
		padding: 3px 6px;
		z-index: 1;
	}
}

.product-title {
	font-size: var(--fs-xl);
	line-height: var(--fs-xl);
	font-weight: bold;
	margin-bottom: 5px;

	&.obsolete {
		color: #fb6a6a;
	}
}

.product-color-selection {
	display: inline-flex;
	vertical-align: bottom;
	align-items: center;
	margin-left: 10px;
}


.product-details {

	@include website-design;

	.variant-main-img {
		text-align: center;
		img {
			max-height: 600px;
			max-width: 100%;
		}
	}
	.variant-color-name {
		text-align: center;
	}
	.variant-images {
		display: flex;
		justify-content: center;
		margin-top: 10px;

		.variant-image {
			cursor: pointer;
			border: 1px solid #c9c9c9;
			border-radius: 5px;
			margin-right: 5px;
			padding: 3px;
			margin-bottom: 5px;

			img {
				width: 30px;
				height: 30px;
			}
		}
	}
	.price-tag {
		position: absolute;
		right: 50px;
		padding: 0px 10px;
		//font-size: 1.2em;
		font-size: var(--fs-lg);
		font-weight: bold;
		color: white;
		background-color: black;
		text-align: center;
		border-radius: 0.25rem;
	}

	.composition {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.brand {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.sku {
		font-weight: bold;
		margin-bottom: 10px;
	}
	.sizes, .main-colors, .other-colors {
		font-weight: bold;
		margin-top: 20px;
	}

	.sizes-list {
		margin-top: 3px;
		display: flex;
		flex-wrap: wrap;
		div {
			margin: 3px;
			padding: 3px 8px;
			//font-size: 0.9em;
			font-size: 1em;
			color: white;
			background-color: black;
			text-align: center;
			border-radius: 0.25rem;
		}
	}

	.product-actions {
		display: flex;
		flex-direction: column;


		.other-versions {
			display: inline-flex;
			align-items: center;
			justify-content: flex-end;

			.other-version {
				border: 1px solid #c9c9c9;
				border-radius: 5px;
				margin-left: 5px;
				width: 22px;
				//height: 22px;
				text-align: center;
				padding: 3px;
				cursor: pointer;

				svg {
					height: 1.5rem;
				}
			}
		}

		.product-buttons {

			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;

			.color-selection {
				display: inline-flex;
				align-items: center;
				padding-top: 10px;
				margin-left: 5px;
				margin-bottom: 5px;
			}
		}
	}


	.variant-colors {
		display: inline-block;
		width: 100%;

		.variant-color {
			display: inline-block;
		}
	}

}

.product-config {

	@include website-design;

	.product-price {
		margin-left: 10px;
	}
	.product-sku {
		font-size: var(--fs-lg);
		position: absolute;
		right: 60px;
		font-weight: 300;
		cursor: pointer;
	}
	.product-version {
		font-size: 1.2em;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.product-boutique-margin {
		padding: 16px 54px 16px 0px;
		font-size: 1.4em;
		text-align: left;
	}

	.product-total-price {
		padding: 16px 54px 16px 0px;
		font-size: 1.4em;
		text-align: right;
	}

	.color-tab-title {
		display: flex;
		position: relative;
		padding-right: 10px;

		.color-name {
			margin-left: 10px;
			font-size: 1.8em;

			&.discontinued {
				color: #fb6a6a;
			}
		}

		svg {
			width: 12px;
			height: 12px;
		}
	}

	.product-img {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid #c9c9c9;
	}
	
	.color-img {
		width: 400px;
		position: relative;

		img {
			max-height: 100%;
			width: 100%;
		}

		.product-img-help {
			margin-top: -1rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.request-preview {
			position: absolute;
			bottom: -40px;
			text-align: center;
			width: 100%;
			margin-left: -16px;
		}
	}

	.choose-logo-position {
		text-align: center;
		margin-top: 20px;
		padding: 30px 30px;
		font-size: 0.8em;
	}
	
	.addon-option {

		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
		
		&.textual {
			margin-bottom: 0px;
		}

		.addon-price {
			display: inline-flex;
			width: 50px;
		}
	}

	.perso-addon-list {

		.perso-color {
			display: inline-block;
			margin-left: 5px;

			.select-color {
				svg {
					right: 0;
				}
			}
		}

		.MuiAccordionSummary-root {
			padding: 0px 0px;
			min-height: 0px;

			.MuiAccordionSummary-content {
				align-items: center;
				margin: 0;

				.perso-colors {
					display: inline-flex;

					.puce {
						margin-left: 3px;
					}
				}


				.MuiFormControlLabel-root .MuiTypography-root {
					font-size: 0.9em;
					font-weight: bold;
				}

			}
		}

		.perso-addon-config {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 30px;


			.perso-name-content {
				display: flex;
				justify-content: left;
				align-items: center;

				.perso-content {
					.select-content {
						svg {
							right: 0;
						}
					}

				}
			}
			.perso-price {
				font-size: 1.2em;
			}
		}

		.perso-addon-config.disabled {
			opacity: 0.3;
		}
	}

	.logo-addon-config {
		margin-bottom: 10px;
		padding: 5px;
		border: 1px solid #A0A0A0;
		border-radius: 5px;

		&.selected {
			background-color: #F0FFF0;
		}

		.addon-name {
			font-size: 1.2em;
			font-weight: bold;
			margin-bottom: 5px;
			display: flex;
			justify-content: space-between;

			.optional.checked {
				.MuiFormControlLabel-label {
					font-weight: bold;
				}
			}
		}

		.addon-logo-price {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;

			.select-addon-logo {
				overflow-x: auto;

				.select-logo-none {
					padding: 4px 10px;

					&.MuiToggleButton-root.Mui-selected {
						background-color: #A0A0A0;
					}
				}

				.select-logo-button {
					padding: 4px 4px;
					min-width: 85px;
					opacity: 0.3;

					.select-logo {

						.dark-bg {
							background-color: #bcbaba;
						}

						img {
							width: 32px;
						}

						.select-logo-version {
							svg {
								right: 0;
							}
						}
					}

					&.MuiToggleButton-root.Mui-selected {
						//background-color: #A0A0A0;
						background-color: #a0bca0;
						opacity: 1;

						.select-logo {

							.dark-bg {
								background-color: #a0bca0;
							}

						}
					}
				}

			}

			.addon-price {
				font-size: 1.5em;

				&.optional {
					opacity: 0.2;
				}
			}
		}
		
	}

	.logo-addon-config.disabled {
		opacity: 0.8;

		.addon-name .name {
			opacity: 0.3;
		}
	}
}

.perso-color-value {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;


	.perso-color-name {
		display: flex;
		align-items: center;

		.puce {
			margin-right: 5px;
		}
	}

	.perso-color-brush {
		
	}

	.editable-color {
		display: flex;
		justify-content: space-between;
	}

	.MuiSvgIcon-root {
		margin-left: 10px;
	}

}

// import variables.scss
@import "../../styles/variables";

.demo-boutique {
	@include website-design;

	.demo-title {
		font-family: var(--font-title), -apple-system, 'Helvetica Neue', sans-serif;
		font-size: var(--fs-xxl);
		color: $dagobaBlue;
		font-weight: 700;
	}

	.demo-subtitle {
		font-size: var(--fs-s);
		color: #808080;
		font-weight: 700;
	}

	.demo-loading {
		text-align: center;
		font-size: var(--fs-md);
		padding-top: 40px;
		color: $dagobaBlue;
	}

	.demo-step-title {
		font-size: var(--fs-md);
		font-weight: bold;
		cursor: pointer;
	}

	.radio-title {
		font-size: var(--fs-md);
		font-weight: bold;
		color: #0e4a85;
	}

	.logo-choice {
		display: flex;
		margin-top: 10px;
		.MuiTypography-root {
			font-size: var(--fs-normal);
			line-height: var(--fs-normal);
			padding-top: 5px;
		}
	}

	.radio-choice {
		transition: color 0.3s;
		&.selected {
			color: $dagobaBlue;
			font-weight: bold;
		}
	}

	.radio-choice:hover {
		color: #1976d2;
		font-weight: bold;
	}


	.logo-thumbnail {
		border: 1px solid #A0A0A0;
		padding: 3px;
		width: 48px;
		max-height: 36px;
		text-align: center;
		position: relative;
		//background: linear-gradient(to bottom right, #F0F0F0, #202020);

		img {
			max-height: 32px;
			max-width: 100%;
		}
	}

	.image-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		padding: 0px;
		margin-bottom: 20px;

		.dropzone button {
			cursor: pointer;
		}

		.load-logo {
			width: 100%;
			min-height: 50px;
			font-size: var(--fs-md);
		}

		.image-item {
			border: 1px solid #A0A0A0;
			padding: 16px;
			//width: 500px;
			text-align: center;
			position: relative;
			//background: linear-gradient(to bottom right, #F0F0F0, #202020);

			.logo-preview {
				//width: 200px;
				padding: 10px;
				position: relative;

				&.light {
					background-color: #F8F8F8;
				}

				&.dark {
					background-color: #101010;
				}
			}

			img {
				max-height: 150px;
				max-width: 100%;
			}

			.image-change {
				position: absolute;
				top: -5px;
				left: -5px;
			}
			.image-delete {
				position: absolute;
				top: -5px;
				right: -5px;
			}

		}
		.errors {
			//position: absolute;
			//width: 80%;
			//top: 10%;
			//margin-top: 10px;
			padding: 10px;
			text-align: center;
			color: red;
			//font-size: 0.85em;
			font-size: var(--fs-s);
			//left: 50%;
			//margin-left: -40%;
			//font-size: 30px;
		}

	}

	.boutique-link {
		cursor: pointer;
		//font-size: 1.5em;
		font-size: var(--fs-md);
		font-weight: bold;
		color: $dagobaBlue;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.dashboard {
	@include website-design;

	width: 100%;
	background-color: $appBg;
	position: relative;
	z-index: 1;

	.section {
		//background-color: $dagobaBlue !important;
		background-color: #8f8f8f !important;
		color: white;
		font-size: var(--fs-md);
		padding-bottom: 3px;
	}

	.plan {
		border: 1px solid #D0D0D0;
		border-radius: 4px;
		padding: 10px;
		margin-bottom: 10px;
		font-size: var(--fs-s);

		.plan-subtitle {
			font-weight: bold;
		}

		.plan-terms-list {
			padding-inline-start: 20px;
		}

		&.standard {
			color: #0f5c2e;
			background-color: #e9fbf0;
			border-color: #c6f6d9;
		}
		&.margins {
			color: #01659c;
			background-color: #e5f6fd;
			border-color: #5397e2;
		}

		&.error {
			color: #ba2d2d;
			background-color: #ffe6e6;
			border-color: #ff9c9c;
		}
	}


	.boutique-selector {
		display: flex;
		//justify-content: space-between;
		align-items: center;
		font-size: var(--fs-s);
		margin: 5px 0 0 0;
		color: $dagobaBlue;
		padding: 5px;
		border-radius: 3px;
		cursor: pointer;

		&.selected {
			color: white;
			background-color: $dagobaBlue;
		}

		&.add {
			display: flex;
			margin-left: -5px;
			color: #014e01;
			opacity: 0.5;
			font-size: 0.9em;
			svg {
				height: 20px;
				margin-right: 5px;
			}
		}

		&.draft {
			color: #FFA500;
			margin-top: -5px;
			margin-left: 15px;
		}

		&.disabled {
			opacity: 0.5;
			cursor: default;
		}
	}

	.empty-boutique {
		padding: 200px 5px;
		text-align: center;
		font-size: 1.5em;
		line-height: 1.5em;
		font-weight: 300;
		color: $dagobaBlue;
	}

	.boutique {
		width: 100%;
		position: relative;
		padding:10px;
		background-color:white;
		border: 2px solid #d5d5d5;
		&.edit {
			border: 2px dashed darkorange;
			background-color: $editBoutiqueBg;
		}
		border-radius: 5px;

		.boutique-header {
			display: flex;
			margin-bottom: 4px;

			.boutique-logo {
				width: 64px;
				height: 64px;
				border: 1px solid #D0D0D0;
				margin-right: 10px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.logo {
					width: 64px;
					height: 64px;
					&.dark-bg {
						background-color: #404040;
					}
				}

			}

			.boutique-title {
				.name  {
					font-size: var(--fs-lg);
					line-height: var(--fs-lg);
					font-weight: bold;
					margin-bottom: 4px;
				}
				.subtitle  {
					font-size: var(--fs-md);
					line-height: var(--fs-md);
					margin-bottom: 4px;
				}
			}
		}
		.infos {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 4px;

			.subdomain-link  {
				//flex-basis: 50%;
				font-size: 0.9em;
				line-height: 2em;
				color: black;
				background-color: $urlBg;
				&.edit {
					background-color: $editURLBg;
				}
				border: 1px solid #AAA;
				border-radius: 3px;
				padding: 0px 5px;
				margin-bottom: 5px;

				display: flex;
				align-items: center;

				.subdomain {

				}
				.draft {
					color: #E0E0E0;
				}
				.open-link {
					cursor: pointer;
					margin-left: 5px;
					margin-top: 5px;
				}
			}
			.acronym  {
				font-size: 0.8em;
				.inline-text {
					font-size: 1.2em;
					color: #000080;
				}
			}

			.color  {
				font-size: 0.8em;
			}

			.social {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}
		}

		.persos {
			display: flex;
			flex-direction: column;
			padding: 10px;
			border: 1px solid #A0A0A0;
			border-radius: 3px;

			.persos-title {
				//font-size: 2em;
				font-weight: normal;
				margin-bottom: 5px;
			}

			.persos-options {
				display: flex;
				justify-content: space-between;
				margin-top: 10px;

				.persos-color {
					width: 35%;

					.pick-color {
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: var(--fs-s);

						.perso-color {
							display: inline-block;
							margin-left: 5px;

							.select-color {
								svg {
									right: 0;
								}
							}
						}

					}
					.perso-preview {
						padding: 15px 0;
						font-size: 35px;
						text-align: center;
						&.light {
							background-color: #E0E0E0;
						}
						&.dark {
							background-color: #404040;
						}

					}
					.perso-warning {
						text-align: center;
						font-size: 0.8em;
						color: #FF0000;
					}
				}
			}
		}

		// used in product config or boutique config
		.perso-color {
			display: flex;
			align-content: center;
			justify-content: center;
			flex-wrap: wrap;

			.color-box {
				display: flex;
				align-content: center;
				width: 24px;
				height: 24px;
				border: 1px solid #CBCBCB;
				margin-right: 5px;
			}
			.color-name {
				display: flex;
				align-items: center;
			}
		}

		.markup {
			display: flex;
			flex-direction: column;
			margin-top: 10px;
			padding: 10px;
			border: 1px solid #A0A0A0;
			border-radius: 3px;

			.markup-title {
				//font-size: 2em;
				font-weight: normal;
				margin-bottom: 5px;
			}
		}
	}

	.plan-name {
		display: flex;
		justify-content: flex-end;
		align-content: center;
		align-items: center;
		font-size: var(--fs-md);
		opacity: 0.7;
		color: #8f8f8f;
	}

	.collection {
		padding: 10px;
		background-color: $collectionBg;
		&.edit {
			background-color: $editCollectionBg;
		}
		&.error {
			background-color: $errorCollectionBg;
		}
		border: 1px solid #A0A0A0;
		border-radius: 3px;
		margin-bottom: 10px;
		position: relative;
		cursor: grab;
		display: flex;
		flex-direction: column;

		.title {
			display: flex;
			align-items: center;
			align-content: center;
			//font-size: 1.2em;
			font-size: var(--fs-md);
			font-weight: bold;
			margin-left: 0;
			margin-top: -8px;
			margin-bottom: 8px;
		}

		.nb-articles {
			margin-left: 8px;
			font-size: 0.9em;
			color: #8f8f8f;
			&.error {
				color: #FF0000;
			}
		}

		.separate {
			display: flex;
			justify-content: flex-end;
			align-content: center;
			align-items: center;
			margin-bottom: -15px;
			//text-align: end;
			.check {
				opacity: 0.3;
			}
			font-weight: 300;

			&.active {
				.check {
					opacity: 1;
				}
				font-weight: 400;
			}

			.help-icon {
				opacity: 1;
			}
		}

		.products {
			margin-left: 10px;
			display: flex;
			flex-wrap: wrap;
			min-height: 100px;
			gap: 10px 24px;

			.col-product {
				position: relative;
				margin-bottom: 5px;
				&.error {
					background-color: #ff9c9c;
				}

				.gender-icon {
					position: absolute;
					z-index: 1;
					top: 3px;
					color: #A0A0A0;
				}

				.attached-left {
					position: absolute;
					top: 40%;
					left: -26px;
					color: #D0D0D0;
					z-index: 1;
				}

				.attached-right {
					position: absolute;
					top: 40%;
					right: -26px;
					color: #D0D0D0;
					z-index: 1;
				}

				//z-index: 1;
				.product-tbn {
					width: 105px;
					margin-right: 5px;
					background-color: white;
					&.has-changes {
						background-color: #e6e3ff;
					}
					&.hard-changes {
						background-color: #ffd18d;
					}
					&.error {
						background-color: #f4a2a2;
					}
					border: 1px solid #D0D0D0;
					border-radius: 4px;
					padding: 3px;
					cursor: pointer;

					&.stick-right {
						border-right: 4px solid #D0D0D0;
						margin-right: -12px;
					}

					&.stick-left {
						border-left: 3px solid #D0D0D0;
						margin-left: -12px;
					}


					.first-img {
						text-align: center;
						position: relative;

						.product-overlay {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-color: rgba(0,0,0,0.4);

							.spinner{
								position: absolute;
								top: 50%;
								left: 60%;
								margin-top: -20px;
								margin-left: -20px;
								color: white;
							}
						}
					}

					.product-footer {
						margin-top: 2px;
						display: flex;
						position: relative;
						//justify-content: space-between;
						//align-items: center;

						.logos {
							display: flex;
							justify-content: left;
							min-height: 20px;

							.logo {
								display: flex;
								width: 18px;
								height: 18px;
								margin-right: 1px;
								border: 1px solid #A0A0A0;
								&.dark-bg {
									background-color: #A0A0A0;
								}
								img {
									margin: 1px;
								}

								.no-logo {
									.icon {
										display: flex;
										height: 18px;
										justify-content: center;
										align-content: center;
										svg {
											font-size: 20px;
										}
									}
								}
							}
						}

						.all-colors {
							position: absolute;
							bottom: 0;
							right: 0;
						}
					}
				}
			}
		}
	}

	.add-collection {
		display: flex;
		justify-content: end;
		margin-bottom: 1rem;
	}

	.logos {

		.temporary {
			text-align: center;
		}

		.logo-container {
			position: relative;
			z-index: 100;

			.logo {

				border: 1px solid #A0A0A0;
				&.vendor {
					border: 2px solid #dba9f8;
				}
				border-radius: 3px;
				cursor: pointer;

				&.dark-bg {
					background-color: #404040;
				}

				img {
					width: 100%;
					height: auto;
				}

				.no-logo {
					display: flex;
					height: 85px;
					justify-content: center;
					align-content: center;
					.icon {
						align-self: center;
					}
				}
			}
		}

	}

}